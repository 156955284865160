import "../styles/ExchangeRate.scss";

import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

const ExchangeRatePage = ({
  currencies,
  logoutHandler,
  updateCurrencyHandler,
}) => {
  // Create local state to handle the user-edited rates
  const [updatedCurrencies, setUpdatedCurrencies] = useState(currencies);
  const [isEdited, setIsEdited] = useState(false);

  // Find the currency with the latest `updatedAt`
  const latestCurrency = currencies?.reduce((latest, item) => {
    return new Date(item.updatedAt) > new Date(latest.updatedAt)
      ? item
      : latest;
  }, currencies[0]);

  useEffect(() => {
    setUpdatedCurrencies(currencies);
  }, [currencies]);

  // Handle input change for buy and sell prices
  const handleInputChange = (id, field, value) => {
    // Remove any non-numeric characters (except for the decimal point) from the input
    const numericValue = value.replace(/[^0-9.]/g, "");

    const newCurrencies = updatedCurrencies.map((currency) => {
      if (currency.id === id) {
        return { ...currency, [field]: numericValue };
      }
      return currency;
    });

    setUpdatedCurrencies(newCurrencies);
    setIsEdited(true);
  };

  // Handle updating rates when "Update rates" button is clicked
  const handleUpdateRates = () => {
    // Call the updateCurrencyHandler prop to send the updated rates to the backend
    updateCurrencyHandler(updatedCurrencies);
    setIsEdited(false);
  };

  return (
    <div className="exchange-rate-container">
      <NavBar logoutHandler={logoutHandler} />
      <div className="rate-container">
        <div className="gr-br-3">
          <div className="rate-table">
            <div className="header">
              <div className="inner-header">
                <div className="white">
                  <span>{formatDate(latestCurrency?.updatedAt)} - </span>
                  <span>{formatTime(latestCurrency?.updatedAt)}</span>
                </div>
                <div>Buying</div>
                <div>Selling</div>
              </div>
            </div>
            <div className="prices">
              {/* Istanbul Prices */}
              {Array.isArray(updatedCurrencies) &&
                updatedCurrencies
                  .filter((item) => item.id !== 4) // Assuming ID 4 refers to Tether, adjust this based on your actual IDs
                  .map((item) => (
                    <div className="item" key={`${item.name}-istanbul`}>
                      <div className="g-3">
                        <div className="title-1">{item.name} /IRR Ist</div>
                      </div>
                      <div className="g-2">
                        <input
                          className="rate-in"
                          value={`$${item.istanbulBuyPrice}`} // Display with the $ sign
                          onChange={(e) =>
                            handleInputChange(item.id, "istanbulBuyPrice", e.target.value)
                          }
                        />
                        <input
                          className="rate-in"
                          value={`$${item.istanbulSellPrice}`} // Display with the $ sign
                          onChange={(e) =>
                            handleInputChange(item.id, "istanbulSellPrice", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  ))}

              {/* Tehran Prices */}
              {Array.isArray(updatedCurrencies) &&
                updatedCurrencies.map((item) => (
                  <div className="item" key={`${item.name}-tehran`}>
                    <div className="g-3">
                      <div className="title-1">{item.name} /IRR Teh</div>
                    </div>
                    <div className="g-2">
                      <input
                        className="rate-in"
                        value={`$${item.tehranBuyPrice}`} // Display with the $ sign
                        onChange={(e) =>
                          handleInputChange(item.id, "tehranBuyPrice", e.target.value)
                        }
                      />
                      <input
                        className="rate-in"
                        value={`$${item.tehranSellPrice}`} // Display with the $ sign
                        onChange={(e) =>
                          handleInputChange(item.id, "tehranSellPrice", e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
            </div>
            <div className="update-btn">
              <button
                className={isEdited ? "btn-edited" : ""}
                onClick={handleUpdateRates}
              >
                Update rates
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeRatePage;
