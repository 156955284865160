import { Link, NavLink } from "react-router-dom";
import "./NavBar.scss";

import logo from "../../assets/logo.png";
import logout from "../../assets/icons/log-out.svg";
import rate from "../../assets/icons/currency_exchange.svg";
import ad from "../../assets/icons/add_ad.svg";
import ann from "../../assets/icons/feedback.svg";
import profile from "../../assets/icons/user.svg";

import React, { Fragment } from "react";

const NavBar = ({ logoutHandler }) => {
  return (
    <Fragment>
      <div className="mini-exit">
        <div className="exit">
          <Link to="/" onClick={logoutHandler} className="logout navlink">
            <img src={logout} alt="logout" />
          </Link>
        </div>
      </div>
      <div className="gr-br-2">
        <div className="navbar-container">
          <div>
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="nav-container">
              <NavLink className={"navlink"} to={"/exchange-rate"}>
                <img src={rate} alt="rate" />
                Exchange Rate
              </NavLink>
              <NavLink className={"navlink"} to={"/profile-setting"}>
                <img src={profile} alt="profile" />
                Profile Setting
              </NavLink>
              <NavLink className={"navlink"} to={"/announcement"}>
                <img src={ann} alt="announcement" />
                Announcement
              </NavLink>
              <NavLink className={"navlink"} to={"/advertisement"}>
                <img src={ad} alt="advertisement" />
                Advertisement
              </NavLink>
            </div>
          </div>
          <div className="exit">
            <Link to="/" onClick={logoutHandler} className="logout navlink">
              <img src={logout} alt="logout" />
              Log out
            </Link>
          </div>
        </div>

        <div className="mini-navbar-container">
          {/* <div>
          <div className="exit">
            <Link to="/" onClick={logoutHandler} className="logout navlink">
              <img src={logout} alt="logout" />
              Log out
            </Link>
          </div>
        </div> */}
          <div className="mini-nav-container">
            <NavLink className={"navlink"} to={"/exchange-rate"}>
              <img src={rate} alt="rate" />
            </NavLink>
            <NavLink className={"navlink"} to={"/profile-setting"}>
              <img src={profile} alt="profile" />
            </NavLink>
            <NavLink className={"navlink"} to={"/announcement"}>
              <img src={ann} alt="announcement" />
            </NavLink>
            <NavLink className={"navlink"} to={"/advertisement"}>
              <img src={ad} alt="advertisement" />
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NavBar;
